import { useEffect, useRef, useState } from "react";
import { newFormInpPropType } from "../../@types/user";
import question_ico from "../../svg/question.svg";
import { Alert, Input } from "antd";
import StringOptimizer from "../../utils/StringOptimizer";
import { useDispatch } from "react-redux";
import { updateTextInput } from "../../redux/calculatorSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const Text = ({activeIndex,index,data,currency,outerIndex}:newFormInpPropType)=>{

    const version = useSelector((state:RootState)=> (state.calculator.tabs[state.calculator.activeTab].version ));
    
    const [showMsg,setShowMsg] = useState<boolean>(false);
    const msgCnt = useRef<any>();
    const msgRef = useRef<any>();
    const dispatch = useDispatch();


    useEffect(()=>{

        window.addEventListener('click',windowClickFunction);

        return ()=>{
            window.removeEventListener('click',windowClickFunction)
        }

      },[]);

      const windowClickFunction = (e:any)=>{
  
        if(msgCnt.current && msgRef.current){

            if (!msgCnt.current.contains(e.target) && !msgRef.current.contains(e.target)){
                setShowMsg(false);
            }

        }
  
      }

      const changefunc = (e:any)=>{

        dispatch ( updateTextInput({
            value:e.target.value,
            outerIndex:outerIndex,
            innerIndex:index
        }));

      }

    return(
        <>
            <div className="formMainCnt">
                <p className="formTitle">{StringOptimizer.updateCurrencyFormat(data?.name || "",currency!)}</p>
                <div className={`formInputCnt ${data?.error && "error"}`}>

                <Input 
                    className="mainInput" 
                    type="text" 
                    style={{flex:"1"}}
                    value={data?.value}
                    onChange={changefunc}
                    disabled={data?.disabled}
                     />

                <img className="formQuestion" src={question_ico} alt="show input field description button"
                onClick={()=> setShowMsg(true)}
                ref={msgRef}
                 />
                </div>

                {
                    data?.error &&
                    <Alert 
                    style={{fontSize:"12px",padding:"5px 10px"}} 
                    description="Mandatory Field" 
                    type="error" 
                    showIcon={false} />
                }
                {
                    showMsg &&
                    <div className="formMsg" ref={msgCnt}>
                        {

                            version === "v3" ?
                            (
                                (data && data.msg) &&
                                data.msg.map((elem,index)=>(
                                    <p key={`msg${index}`}>{elem}</p>
                                )) 
                            )
                            :
                            <p>{data?.msg}</p>
                       }
                    </div>
                }
            </div>
        </>
    )

}

export default Text;