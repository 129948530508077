import React, {createContext, useEffect, useState } from "react";
import { GlobalContextType } from "../@types/user";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import ModalBox from "../components/modal";
import { Axios } from "../Axios";
import { login, logout } from "../redux/userSlice";
import { setAlreadySigned, setUnAuthorized } from "../redux/globalSlice";

export const globalData = createContext<GlobalContextType | null >(null);

type contextProp={
    children:React.ReactNode
}

export const GlobalContext = (props:contextProp)=>{

  const location = useLocation();
  // this holds the active manu tab for designing purpose.
  const [ activeTab, setActiveTab ] = useState<Number>(0);
  const unauthorized = useSelector((state: RootState) => state.global.unauthorized );
  const alreadySigned = useSelector((state: RootState) => state.global.alreadySignedIn );
  const dispatch = useDispatch();
  const [allow,setAllow] = useState(false);

  useEffect(()=>{
   
    let xx = location.pathname;

    if(xx == "/home")
    setActiveTab(0);
    else if(xx == "/histories")
    setActiveTab(1);
    else if(xx == "/users")
    setActiveTab(2);
   
  },[location]);

  // auto sets the user if user is not logged out last time
  useEffect(()=>{

    const usr = localStorage.getItem("ccouser") || sessionStorage.getItem("ccouser");

    if(usr){
      const usr2 = JSON.parse(usr);

      Axios.post(`/api/v1/background-authentication`,{},{
        headers:{
          Authorization:usr2?.token
        }
      })
      .then(result=>{

        dispatch( login(JSON.parse(usr)) );
        setAllow(true);
        
      })
      .catch(err=>{
        setAllow(true);
      })
      
    }
    else{

      setAllow(true);

    }

  },[]);

    
    return (
      <globalData.Provider value={{ activeTab, setActiveTab }}>
        {
          allow &&
          <>
          {props.children}
          </>
        }

        <ModalBox
        open={unauthorized}
        onClose={()=> {} }
        header="Restricted !"
        message={"Login session expired. Please Login again !"} 
        onOk={()=>{
          dispatch( logout() );
          dispatch(setUnAuthorized(false) );
          setAllow(true);
        }}
        />

        <ModalBox
        open={alreadySigned}
        header="Restricted !"
        message={"You are already Signed-in to another device. Please login again to continue with this device !"} 
        onOk={()=>{
            dispatch( logout() );
            dispatch(setAlreadySigned(false) );
            setAllow(true);
        }}
        />
      </globalData.Provider>
    );
}
