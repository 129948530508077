import { Button } from "antd";
import Style from "../../styles/output.module.css";
import { useEffect, useState } from "react";
import { resultType } from "../../@types/user";
import Chart  from "react-apexcharts";
import StringOptimizer from "../../utils/StringOptimizer";
import ScrollerWrapper from "../scrollerWrapper";
import InputBox from "../input";


type propType ={
    myresult: {
        name:string,
        output:{
            name:string,
            data:{
                name:string,
                type:string,
                value:number
            }[]
        }[]
    
    }[]
}

const AnalysisScreen = ({myresult}:propType)=>{

    const [pps,setPps] = useState<string[]>([]);
    const [nso,setNso] = useState<string[]>([]);
    const [npv,setNpv] = useState<string[]>( myresult.map(elem=> elem.output[0].data[12].value.toString() ));
    const [result,setResult] = useState<{spi:string[],spip:string[],pfs:string[]}>({
        spi:[],
        spip:[],
        pfs:[]
    })

    useEffect(()=>{
        console.log("Analysis",myresult)
        myresult.forEach((element,index) => {
            calcuate(index);
        });
    },[nso,pps]);



    const calcuate = (index:number)=>{
        let spi = (parseFloat(npv[index]) || 1) / (parseFloat(nso[index]) || 1);
        let spip = ((spi / (parseFloat(pps[index])) ) * 100) || 0; 
        let pfs = spi + (parseFloat(pps[index]) || 0);

        let x = {...result};
        x.spi[index] = spi.toFixed(2);
        x.spip[index] = spip.toFixed(2);
        x.pfs[index] = pfs.toFixed(2);
        
        setResult(x);

    }


    return(
        <div className="chartBoxCnt" style={{margin:"0 auto"}}>
            {
                myresult.map((elem,index)=>(
                    <div className="modalPopUp">
                        <div style={{
                            margin:"0 0 25px 0"
                        }}>
                            <h3>{elem.name}</h3>
                        </div>
                        <div>
                        <InputBox
                            type={"Cnumber"}
                            placeholder={"Current Price Per Share"}
                            value={pps[index] ? pps[index] : ""}
                            currency={true}
                            onChange={(e:any)=>{

                                let x = [...pps];
                                x[index] = e.target.value;
                                setPps(x);

                            }}
                            />
                        </div>

                        <div>
                        <InputBox
                            type={"Cnumber"}
                            placeholder={"Number of Shares Outstanding"}
                            value={nso[index] ? nso[index] : ""}
                            currency={true}
                            onChange={(e:any)=>{

                                let x = [...nso];
                                x[index] = e.target.value;
                                setNso(x);
                            }}
                            />
                        </div>

                        <div>
                        <InputBox
                            type={"Cnumber"}
                            placeholder={"Enterprise NPV"}
                            value={npv[index] ? npv[index] : ""}
                            currency={true}
                            onChange={(e:any)=>{
                                e.preventDefault();

                            }}
                            style={{cursor: "not-allowed"}}
                            disabled={true}
                            />
                        </div>

                        <div style={{
                            margin:"50px 0 0 0"
                        }}>
                            <p style={{
                                color: "rgb(39, 59, 139)"
                            }}>Result</p>
                            <table style={{
                                borderSpacing:"10px",
                                fontSize:"14px"
                            }}
                            >
                                <thead>
                                    <tr>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Share Price Improvement</td>
                                        <td>
                                        <InputBox
                                        type={"Cnumber"}
                                        placeholder={""}
                                        value={result.spi[index] ? result.spi[index].toString() : ""}
                                        currency={true}
                                        onChange={(e:any)=>{
                                            e.preventDefault();

                                        }}
                                        style={{cursor: "not-allowed"}}
                                        disabled={false}
                                        />
                                            {/* <div style={{
                                                width:"170px",
                                                border:"1px solid gainsboro",
                                                padding:"5px 10px",
                                                borderRadius:"5px",
                                                color:"gray"
                                                }}>
                                            {result.spi}
                                            </div> */}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Share Price % Improvement</td>
                                        <td>
                                        <InputBox
                                        type={"Cnumber"}
                                        placeholder={""}
                                        value={result.spip[index] ? result.spip[index].toString() : ""}
                                        percent={true}
                                        onChange={(e:any)=>{
                                            e.preventDefault();

                                        }}
                                        style={{cursor: "not-allowed"}}
                                        disabled={false}
                                        />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Projected Future State Share Price</td>
                                        <td>
                                        <InputBox
                                        type={"Cnumber"}
                                        placeholder={""}
                                        value={result.pfs[index] ? result.pfs[index].toString() : ""}
                                        currency={true}
                                        onChange={(e:any)=>{
                                            e.preventDefault();

                                        }}
                                        style={{cursor: "not-allowed"}}
                                        disabled={false}
                                        />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        
                        </div>

                    </div>
                     
                ))
            }
        
        </div>
    )
}

export default AnalysisScreen;