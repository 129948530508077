import { IconButton } from "@mui/material";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useRef, useState } from "react";
import PopupMenu from "../menu/PopupMenu";
import Style from "./tdm.module.css";

type propType = {
    items:any[],
    rowId:string
}

const TripleDotMenu = ({items,rowId}:propType)=>{

    const [showMenu,setShowMenu] = useState(false);
    const mTarget = useRef<any>();

    const handleClick = ()=>{

        setShowMenu(false);

    }

    return(
        <>
        {
            showMenu &&
            <PopupMenu
            targetElem={mTarget.current}
            onCloseTrigger={()=> setShowMenu(prev=> !prev)}
            >
            <ul className={Style.cnt}>
                {
                    items.map((elem,index)=>(
                        <li onClick={handleClick}>{elem}</li>
                    ))
                }

            </ul>
            </PopupMenu>
        }
        <IconButton 
        sx={{margin:"auto 15px auto 0",height:"35px",width:"35px"}}
        ref={mTarget}
        onClick={(e)=> setShowMenu(true) }
        >
            <MoreVertIcon 
            fontSize='small'
            // className='addScenarioBtn'
            />
        </IconButton>
        </>
    )

}

export default TripleDotMenu;