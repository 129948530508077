import { Input } from "antd";
import { useState } from "react";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

type propType={
    value:string
}
const PassToggle = ({value}:propType)=>{

    const [showPass,setShowPass] = useState(false);

    const hideIt = (val:string)=>{
        return val.split("").map(elem=> "*").join("");

    }

    return(
        <div
        style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center"
        }}
        >
        <div>{showPass ? value : hideIt(value)}</div>
        <div style={{margin:"0 0 0 7px"}}>
        {
            !showPass &&
            <VisibilityIcon fontSize="small" onClick={()=> setShowPass(prev=> !prev) } />
        }
        {
            showPass &&
            <VisibilityOffIcon fontSize="small" onClick={()=> setShowPass(prev=> !prev) } />
        }
        </div>
        </div>

    )

}

export default PassToggle;