import { Timeline } from "antd";
import { CalculatorType } from "../../@types/user";
import hollow_circle from "../../svg/hollow_circle.svg"
import tick from "../../svg/tick.svg";
import empty_circle from "../../svg/empty_circle.svg";
import form_err from "../../svg/form_err.svg";

import Style from "../../styles/timeline.module.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { verifyFormPage } from "../../redux/calculatorSlice";

type props={
    calcList:CalculatorType,
}
const TimelineContainer = ({calcList}:props)=>{

    const page = useSelector((state: RootState) => (state.calculator.activeScreen?.jobs[state.calculator.activeJob].activeIndex || 0));
    const dispatch = useDispatch();

    return(
        <>
        <Timeline 
        rootClassName={Style.timelineCnt}
        items={
            calcList.map((elem,index)=>(
                {
                    children:(
                        <p onClick={()=> dispatch(verifyFormPage(index)) }  className={
                            `${Style.tPara} ${
                            elem.cmp ?
                                page === index ?
                                Style.lineCurrent :
                                Style.lineActive 
                            :
                                page === index ?
                                Style.lineCurrent 
                                :
                                elem.pageErr ?
                                    Style.lineErr 
                                    :
                                    Style.lineInactive
                            }`
                        }
                        >
                            {elem.name}
                        </p>
                    ),

                    dot:(
                        <>
                        {
                            elem.cmp ?
                            <img onClick={()=> dispatch(verifyFormPage(index))  } className={Style.dot} src={tick} style={{width:"20px"}} alt="icon" /> 
                        :
                            elem.pageErr ?
                                <img onClick={()=> dispatch(verifyFormPage(index))  } className={Style.dot} src={form_err} style={{width:"20px"}} alt="icon" />
                            :
                                page === index ?
                                    <img onClick={()=> dispatch(verifyFormPage(index))  } className={Style.dot} src={hollow_circle} style={{width:"20px"}} alt="icon" />
                                :
                                    <img onClick={()=> dispatch(verifyFormPage(index))  } className={Style.dot} src={empty_circle} style={{width:"20px"}} alt="icon" /> 
                        }
                        </>
                    )
               
                }
            ))
        }
        />
            

        </>
    )

}

export default TimelineContainer;