import ControlPointIcon from '@mui/icons-material/ControlPoint';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import BottomTab from '../tabs/BottomTab';
import { scenarioType } from '../../@types/user';
import { Button, Select } from 'antd';
import { FormControl, IconButton, MenuItem, Modal } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import PopupMenu from '../menu/PopupMenu';
import { CloseOutlined } from '@mui/icons-material';
import InputBox from '../input';
import { RightOutlined } from '@ant-design/icons';
import ModalBox from '../modal';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { insertScenario } from '../../redux/calculatorSlice';

const ScenarioBar = ()=>{

    const activeTabScenarios = useSelector((state: RootState) => state.calculator.activeTabScenarios);
    const dispatch = useDispatch();

    const [showMenu,setShowMenu] = useState(false);
    const addSBtn = useRef<any>();
    const [newScenarioModal,setNewScenarioModal] = useState(false);
    const [newSplitScreenModal,setsplitScreenModal] = useState(false);
    const [selectedNewScenario,setSelectedNewScenario] = useState("0");
    const [newScenarioName,setNewScenarioName] = useState("");
    const [newScenarioDesc,setNewScenarioDesc] = useState("");
    const [error,setError] = useState("");
  

    const newScenario = (e:React.MouseEvent<any>)=>{
        setNewScenarioModal(true);
        setShowMenu(false);
    }

    const confirmScenarioCreation = ()=>{
        if(!newScenarioName){
            setError("Please enter a name for new Scenario !");
            return;
        }
        if(!newScenarioDesc){
            setError("Please enter a description for new Scenario !");
            return;
        }

        dispatch( insertScenario({
            from:parseInt(selectedNewScenario),
            myname:newScenarioName,
            mydesc:newScenarioDesc
        }) )

   
        setNewScenarioModal(false);
        setSelectedNewScenario("0");
        setNewScenarioName("");
        setNewScenarioDesc("");
    }


    return(
        <>
        <div className="bottomBarCnt">
            <div className="barScrollCnt barScrollCollapsed">
                
                {/* <BottomTab 
                    index={0}
                    name={activeTabScenarios[0].name}
                    type={activeTabScenarios[0].type}
                    /> */}
                {/* <BottomTab 
                    index={1}
                    name={activeTabScenarios[1].name}
                    type={activeTabScenarios[1].type}
                    />       */}
                {
                    // activeTabScenarios.length>1 &&
                    // <DragHandleIcon
                    //     className='bottomTabSeperator' 
                    //     />
                }
                {
                    activeTabScenarios.map((elem,index)=>(
                        // (index > 0 && elem.type === 1) &&
                        <BottomTab 
                        key={`bt${index}`}
                        index={index}
                        name={elem.name}
                        type={elem.type}
                        showClose={activeTabScenarios.length > 1 ? true : false}
                        />
                    ))
                }

            </div>
           
      
            <IconButton 
            sx={{margin:"auto 15px auto 0",height:"35px",width:"35px"}}
            ref={addSBtn}
            onClick={()=> setShowMenu(true)}
            >
                <ControlPointIcon 
                fontSize='medium'
                className='addScenarioBtn'
                />
            </IconButton>
        </div>
        {
            showMenu &&
            <PopupMenu
            targetElem={addSBtn.current}
            onCloseTrigger={()=> setShowMenu(false)}
            >
                <ul>
                    <li onClick={newScenario}>New Scenario</li>
                </ul>
            </PopupMenu>
            
        }
        {
            newScenarioModal &&
            <Modal
            className="modal"
            open={true}
            onClose={()=> {
                setNewScenarioModal(false);
            }}
           >
   
           <div className="modalPopUp">
   
                   <CloseOutlined
                   className="popupClose" 
                   onClick={()=> {
                    setNewScenarioModal(false);
                   }}
                   />
                       <div className="mpuTop">
   
                       <div>
                       
                        <InputBox
                               type="select"
                               placeholder={"New Scenario From"}
                               value={selectedNewScenario}
                               options={
                                [
                                    {value:"-1",label:"Blank"},
                                    ...activeTabScenarios.filter(elem=> elem.type !== 2 ).map((elem,index)=> ({value:`${index}`, label : elem.name}) )
                                ]
                            }
                               onChange={(e:any)=>{
                                 setSelectedNewScenario(e.target.value);
                               }}
                               />
                           <InputBox
                               type="string"
                               placeholder={"Scenario Name"}
                               value={newScenarioName}
                               onChange={(e:any)=>{
                                 setNewScenarioName(e.target.value)
                               }}
                               />
   
                           <InputBox
                               type="textarea"
                               placeholder={"Scenario Description"}
                               value={newScenarioDesc}
                               onChange={(e:any)=>{
                                 setNewScenarioDesc(e.target.value);
                               }}
                               />
   
                       </div>
                 
   
                       </div>
   
                       <div className="mpuBot">
   
                       <Button className="mybtn-blue" type="text" onClick={()=>{
                            confirmScenarioCreation();
                     
                       }}>
                           Add Scenario <RightOutlined />
                       </Button>
   
                       </div>
   
                   </div>        
           </Modal>
        }
        {
            error &&
            <ModalBox
            open={error ? true :false}
            header={"Scenario Warning !"}
            message={error}
            onClose={()=>{
                setError("");
            }}
            onOk={()=>{
                setError("");
            }}
            />
        }
        </>
    )

}

export default ScenarioBar;