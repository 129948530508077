class StringOptimizer{

    updateCurrencyFormat(str:string,currency:string){

        if(currency === "dollar")
            return str.replace(/\₹/g,"$");
        else if(currency === "rupee")
            return str.replace(/\$/g,"₹");
        else
            return str;

    }

    nameToGraphicalName(val:string){
        let arr = val.trim().split(" ");

        let newarr = [];
        let innerArr = [];

        for(var i=0; i<arr.length; i++){
            if(i % 3 === 0){
                newarr.push(innerArr);
                innerArr = [];
            }

            innerArr.push(arr[i]);
        }

        if(innerArr.length > 0)
        newarr.push(innerArr);

        return newarr.map(elem=> (elem.join(" ")) );
    }
}

export default new StringOptimizer;