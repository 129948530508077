import React,{ useEffect } from 'react';
import './App.css';
import Login from './pages/Login';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import Users from './pages/Users';
import Forget from './pages/Forget';
import Otp from './pages/Otp';
import Histories from './pages/Histories';
import Demo from './pages/Demo';

const App = ()=>{

  return (
    <>

      <Routes>

        <Route path='/' element={<Login />} />
        <Route path='/home' element={<Home />} />
        <Route path='/histories' element={<Histories />} />
        <Route path='/users' element={<Users />} />
        <Route path='/forget-password' element={<Forget />} />
        <Route path='/verify' element={<Otp />} />
        
      </Routes>

    </>
  );
}

export default App;
