import InputBox from '../components/input';
import Style from '../styles/login.module.css';
import pass_prefix from "../svg/pass-prefix.svg";
import Button from 'antd/es/button';
import { useEffect, useState } from 'react';
import axios from "axios";
import { API_URL } from '../config';
import { Alert } from 'antd';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import OtpInput from 'react-otp-input';
import NotAuthenticatedLayout from '../components/layouts/NotAuthenticatedLayout';

const Otp = ()=>{

    const [searchParams] = useSearchParams();
    const refId = searchParams.get("key");
    const navigator = useNavigate();
    const [loading, setLoading] = useState(false);
    const [error,setError] = useState<string | undefined>();
    const [reError,setReError] = useState<string | undefined>();
    const [allowView,setAllowView] = useState(false);

    const [otp,setOtp] = useState<string | undefined>("");
    const [reSuccess,setResuccess] = useState<boolean>(false);
    const [verified,setVerified] = useState<boolean>(false);
    const [success,setSuccess] = useState<boolean>(false);

    const [npassErr, setNpassErr] = useState<string | undefined>();
    const [password, setPassword] = useState<string>("");
    const [cPassword, setCpassword] = useState<string>("");

    const resend = ()=>{
        
        setReError(undefined);

        axios.post(`${API_URL}/api/v1/resend-otp`,{
            refId : refId,
            type : "forget"
        })
        .then(result=>{

            setResuccess(true);
            setTimeout(()=>{
                setResuccess(false);
            },2000)
        })
        .catch(err=>{

            setReError(err.response.data.message);
            setTimeout(()=>{
                setReError(undefined);
            },2000)

        })
    }

    const submit = ()=>{
        setLoading(true);
        setError(undefined);

        axios.post(`${API_URL}/api/v1/verify`,{
            refId : refId,
            type : "forget",
            otp : otp
        })
        .then(result=>{

            setLoading(false);
            setVerified(true);

        })
        .catch(err=>{

            console.log(err);
            setLoading(false);
           if(err.code === "ERR_BAD_REQUEST" &&
           err.response.data.type === 0
           )
           {
            setError("OTP cannot be empty!");
           }
           else{
            setError(err.response.data.message);
           }
            
            

        })
    }


    const resetPass = ()=>{
        setLoading(true);

        axios.post(`${API_URL}/api/v1/reset-password`,{
            refId : refId,
            otp: otp,
            newPassword : password,
            cPassword : cPassword
        })
        .then(result=>{

            setLoading(false);
            setSuccess(true);
        })
        .catch(err=>{
            setLoading(false);
            setNpassErr(err.response.data.message);
        })
    }

     return (
        <>
        <NotAuthenticatedLayout>
        {
            <>
            <div id="main">
                <div className={Style.container}>

                <div className={Style.left}>
                    {
                        (!success && !verified) &&
                        <div className={Style.form}>
                        <img src='/images/cco-small.png' alt="logo" />
                        <h1 className={Style.title}>OTP</h1>
                        <h4 className={Style.subtitle}>we have sent one-time-password to your email.</h4>
                        <h4 className={Style.subtitle}>OTP gets expired in 2 minutes.</h4>

                        <div style={{margin:"20px 0 0 0"}}>
                        <OtpInput
                                value={otp}
                                onChange={setOtp}
                                inputType='number'
                                numInputs={4}
                                containerStyle={{
                                    margin:"0 auto 20px auto",
                                    display:"flex",
                                    justifyContent:"space-between"
                                }}
                                inputStyle="otpInputStyle"
                                renderSeparator={<span></span>}
                                renderInput={(props) => <input {...props} />}
                                />
                        </div>

                        {
                            error &&
                            <Alert 
                            type='error' 
                            message={error} 
                            showIcon 
                            style={{minHeight:"20px", margin:"5px 0 20px 0",fontSize:"12px"}}
                            />
                        }
                    

                        <Button className='btn' type='text' loading={loading} onClick={submit}>Submit</Button>


                        <p style={{color:"#656A72",fontSize:"12px",margin:"30px 0 0 0",cursor:"pointer"}}>
                            Didn't receive the code? 
                                <span style={{color:"#2B80FF"}} onClick={resend}> Resend</span>
                            
                        </p>

                        {
                            reError &&
                            <Alert 
                            type='error' 
                            message={reError} 
                            showIcon 
                            style={{minHeight:"20px", margin:"5px 0 20px 0",fontSize:"11px"}} 
                            />
                        }
                        
                        {
                            reSuccess &&
                            <Alert 
                            type='success'
                            message="OTP Sent Successfully!"
                            showIcon 
                            style={{minHeight:"20px", margin:"5px 0 20px 0",fontSize:"11px"}} 
                            />
                        }

                    </div>
                    }

                    {

                        (!success && verified) &&
                        <div className={Style.form}>
                        <img src='/images/cco-small.png' alt="logo" />
                        <h1 className={Style.title}>Password</h1>
                        <h4 className={Style.subtitle}>Set a new password.</h4>

                        <div style={{margin:"20px 0 0 0"}}>
                        <InputBox 
                        type='password' 
                        image={<img src={pass_prefix} 
                        alt='logo' />} 
                        placeholder='Password'
                        value={password}
                        onChange={(e)=> setPassword(e.target.value) }
                        />
                         <InputBox 
                        type='password' 
                        image={<img src={pass_prefix} 
                        alt='logo' />} 
                        placeholder='Confirm Password'
                        value={cPassword}
                        onChange={(e)=> setCpassword(e.target.value) }
                        />
                        </div>

                        {
                            npassErr &&
                            <Alert 
                            type='error' 
                            message={npassErr} 
                            showIcon 
                            style={{minHeight:"20px", margin:"5px 0 20px 0",fontSize:"12px"}} 
                            />
                        }
                        

                        <Button className='btn' type='text' loading={loading} onClick={resetPass}>Set Password</Button>

                    </div>
                    }

                    {
                        success &&
                        <>

                       <div className={Style.form}>
                       <Alert 
                            type='success'
                            message="Password Reset Successfully"
                            showIcon 
                            style={{minHeight:"20px", margin:"5px 0 20px 0",fontSize:"16px",textAlign:"center"}} 
                            />
                        <Link to="/" style={{color:"#656A72"}}>
                        <p style={{color:"#656A72",fontSize:"14px",margin:"30px 0 0 0",textAlign:"center"}}>
                            <span style={{color:"#2B80FF"}}>Please Login to Continue!</span>
                        </p>
                        </Link>
                       </div>                            
                        </>
                    }
                    

                </div>

                <div className={Style.right} style={{
                background: 'url("/images/forget_bk.png")',
                backgroundPosition: 'right center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
            }}>

                </div>

                </div>

            </div>            
            </>
        }
        </NotAuthenticatedLayout>
        </>
    )
}

export default Otp;