import {memo, useCallback,useEffect,useRef,useState} from "react";
import Header from "../components/Header";
import { CalculatorChildType, CalculatorType, scenarioType, tabType } from '../@types/user';
import { useNavigate } from "react-router-dom";
import Calculator from "../components/Calculator";
import { PlusOutlined } from "@ant-design/icons";
import TopTab from "../components/tabs/TopTab";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { insertTab, setTimer } from "../redux/calculatorSlice";
import ScenarioBar from "../components/bottomBar/ScenarioBar";
import JobBar from "../components/bottomBar/JobBar";
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { IconButton } from "@mui/material";
import AuthenticatedLayout from "../components/layouts/AuthenticatedLayout";

const Home = ()=>{

    const [allowView,setAllowView] = useState(false);
    const calcCnt = useRef<any>();

    const tabs = useSelector((state: RootState) => state.calculator.tabs);
    const activeTab = useSelector((state: RootState) => state.calculator.activeTab);
    const activeScenario = useSelector((state: RootState) => state.calculator.activeScenario);
    const dispatch = useDispatch();


    useEffect(()=>{

        if(tabs.length < 1){
            dispatch(insertTab());
        }
        setAllowView(true);

        let x = setInterval(()=>{
            dispatch( setTimer(getRealTime()) )
          },1000);

          return()=>{
            clearInterval(x);
          }
    },[])

    const getRealTime = ()=>{
        let date = new Date().toDateString();
        let time = new Date().toLocaleTimeString();

        return `${date}, ${time}`;
    }

    return(
        <>
        <AuthenticatedLayout>
        {
            allowView &&
            <>
            <div id="main" style={{paddingTop: "80px"}}>
                <Header />

                <div className="barCnt">
                    <div className="barScrollCnt barScrollCollapsed">
                        {
                            tabs.map((elem,index)=>(
                                <TopTab
                                key={elem.key}
                                index={index}
                                tabLength={tabs.length}
                                name={elem.tabName}
                                />
                            ))
                        }
                    </div>
                    <IconButton className="bcAdd" onClick={()=> {dispatch(insertTab()) } }>
                        <ControlPointIcon
                        sx={{
                            color:"black"
                        }}
                        />
                    </IconButton>

                </div>

                <JobBar
                key={activeScenario+activeTab}
                />
                
                <ScenarioBar 
                />

                

                <div className="calcScreenCnt" ref={calcCnt}>
                   {
                    
                     <Calculator 
                     key={`${activeTab}${activeScenario}`}
                     className="calcScreen"
                     />
                   }
                </div>

            </div>
            </>
        }
        </AuthenticatedLayout>
        </>
    )
}

export default memo(Home);