import Checkbox from 'antd/es/checkbox/Checkbox';
import InputBox from '../components/input';
import Style from '../styles/login.module.css';
import email_prefix from "../svg/email-prefix.svg";
import pass_prefix from "../svg/pass-prefix.svg";
import Button from 'antd/es/button';
import { Alert } from 'antd';
import { Link } from "react-router-dom";
import { Axios } from '../Axios';

import NotAuthenticatedLayout from '../components/layouts/NotAuthenticatedLayout';
import { useDispatch } from 'react-redux';
import { login } from '../redux/userSlice';
import { useState } from 'react';
import Flynaut from '../components/branding/Flynaut';
import { resetCalc } from '../redux/calculatorSlice';

const Login = ()=>{

    const [ email, setEmail ] = useState<string | undefined>("");
    const [ password, setPassword ] = useState<string | undefined>("");
    const [loading, setLoading] = useState(false);
    const [error,setError] = useState<string | undefined>();
    const [rememberMe, setRememberMe] = useState(true);
    const dispatch = useDispatch();


    //  Login function
    const loginHandler = ()=>{
        setLoading(true);
        setError(undefined);

        Axios.post(`/api/v1/login`,{
            email:email,
            password:password
        })
        .then(result=>{
            setLoading(false);
            setError(undefined);
            const user = {...result.data.data.user,token:result.data.data.token};
            
            dispatch(  resetCalc() );
            dispatch( login(user)); 
            
            if(rememberMe){
                localStorage.setItem("ccouser",JSON.stringify(user));
            }
            else{
                sessionStorage.setItem("ccouser",JSON.stringify(user));
            }
            
        })
        .catch(err=>{
            setLoading(false);
            setError(err.response.data.message);
            console.log("error", err);
        })
    }

    return (
        <>
        {
            <NotAuthenticatedLayout>

                <div id="main">
                <div className={Style.container}>

                <div className={Style.left}>
                    <div className={Style.form}>
                        <div className={Style.logoCnt}>
                            {/* <img src='/images/cco-small.png' alt="logo" /> */}
                            <Flynaut />
                        </div>
                        <h1 className={Style.title}>Sign in</h1>
                        <h4 className={Style.subtitle}>Sign in with your email</h4>

                        <div style={{margin:"20px 0 0 0"}}>
                        <InputBox 
                        type='text' 
                        image={<img src={email_prefix} 
                        alt='logo' />} 
                        placeholder='Your email'
                        value={email}
                        onChange={(e)=> setEmail(e.target.value) }
                        />
                        <InputBox 
                        type='password' 
                        image={<img src={pass_prefix} alt='logo' />} 
                        placeholder='Your password'
                        value={password}
                        onChange={(e)=> setPassword(e.target.value) }
                        />
                        </div>

                        {
                            error &&
                            <Alert 
                            type='error' 
                            message={error} 
                            showIcon 
                            style={{minHeight:"25px", margin:"5px 0 20px 0",fontSize:"12px"}} 
                            />
                        }
                        <div style={{display:"flex",justifyContent:"space-between",margin:"15px 0"}}>
                            <Checkbox 
                            checked={rememberMe}
                            onChange={()=> setRememberMe(!rememberMe)}
                            style={{color:"#656A72",fontSize:"12px",alignItems:"center"}}
                            >
                                Remember me
                            </Checkbox>
                            <Link to="/forget-password"style={{textDecoration:"none"}} >
                                <p style={{color:"#2B80FF",fontSize:"12px"}}>Forget password?</p>
                            </Link>
                        </div>

                        <Button className='btn' type='text' loading={loading} onClick={loginHandler}>Login</Button>

                        <p style={{color:"#656A72",fontSize:"12px",margin:"30px 0 0 0"}}>
                            Don't have an account? <span style={{color:"#2B80FF"}}>Contact Us</span>
                        </p>

                    </div>

                </div>

                <div className={Style.right} style={{
                    background: 'url("/images/login_bk.png")',
                    backgroundPosition: 'right center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: '100%',
                }}>

                </div>

                </div>

                </div>

            </NotAuthenticatedLayout>
        }
        </>
    )

}

export default Login;