import { useEffect, useRef, useState } from "react";
import { newFormInpPropType } from "../../@types/user";
import { Alert, Button } from "antd";
import { Modal } from "@mui/material";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import InputBox from "../input";
import Question from "../../svg/question";
import { useDispatch } from "react-redux";
import { doublePopUpInnerInpChange, doublePopUpSecSubmit, oneScreenPopupValueChange, popupInputChange, popupInputVerify } from "../../redux/calculatorSlice";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const OneScreenPopup = ({activeIndex,index,data,outerIndex,currency}:newFormInpPropType)=>{

    const version = useSelector((state:RootState)=> (state.calculator.tabs[state.calculator.activeTab].version ));

    const [showPopup,setPopUp] = useState<boolean>(false);
    const [showMsg,setShowMsg] = useState<boolean>(false);
    const [pass,setPass] = useState(false);
    const msgCnt = useRef<any>();
    const msgRef = useRef<any>();

    const dispatch = useDispatch();


    useEffect(()=>{

        window.addEventListener('click',windowClickFunction);

        return ()=>{
            window.removeEventListener('click',windowClickFunction)
        }

      },[]);

      useEffect(()=>{
        checkPass();
      },[data?.dataArr])

    const windowClickFunction = (e:any)=>{

    if(msgCnt.current && msgRef.current){

        if (!msgCnt.current.contains(e.target) && !msgRef.current.contains(e.target)){
            setShowMsg(false);
        }

    }

    }


    const clickFunc = (e:any)=>{

        setPopUp(true);
    }

    // / function for making bar green or not
    const checkPass = ()=>{
        let c = 0;
        let t =0;
        data?.dataArr?.forEach(element => {
            element.data.forEach(elem=>{
                if(elem.required){
                    if(elem.value){
                        c++;
                    }
                    t++;
                }
             
            })
          
        });

        if(c === t){
            setPass(true);
        }
        else{
            setPass(false);
        }
    }

    const verify = ()=>{
        let err = false;

        if(data?.required){

            data?.dataArr?.forEach(element => {
                element.data.forEach((elem)=>{
                    if(!elem.value){
                        err = true;
                    }
                })
               
            });

            if(err){
                setPass(false);
            }
            else{
                setPass(true);
            }

            dispatch( doublePopUpSecSubmit({
                value:JSON.stringify({error:err}),
                outerIndex,
                innerIndex : index
            }));

   
        }
        else{

            dispatch( doublePopUpSecSubmit({
                value:JSON.stringify({error:false}),
                outerIndex,
                innerIndex : index
            }));
            
            checkPass();
        }

        setPopUp(false);
    }

    return(
        <>
        <Modal
            className="modal"
            open={showPopup}
            onClose={verify}
            >
                <div className="modalPopUp">

                <CloseOutlined
                className="popupClose" 
                onClick={()=> {
                    setPopUp(false);
                } }
                />
                    <div className="mpugTop">

                    {
                            data?.dataArr?.map((elem,ind)=>(
                                <div key={index}>
                                    <h4 style={{margin:"10px 0",color:"#24336D"}}>
                                        {
                                           `${elem.columnName}`
                                        }
                                        </h4>
                                    {
                                        elem.data.map((ielem,iindex)=>(
                                            <InputBox
                                            key={`${index}${iindex}`}
                                            type={ielem.type}
                                            placeholder={ielem.name}
                                            value={ielem.value}
                                            percent={ielem.perc}
                                            onChange={(e:any)=>{
                                                
                                                dispatch(oneScreenPopupValueChange({
                                                    value:JSON.stringify({
                                                        index:ind,
                                                        iindex:iindex,
                                                        data:e.target.value
                                                    }),
                                                    outerIndex : outerIndex,
                                                    innerIndex : index
                                                }))


                                            }}
                                            />
                                        ))
                                    }
                                </div>
                            ))
                        } 
              

                    </div>

                    <div className="mpuBot">

                    <Button className="mybtn-blue" type="text" onClick={verify}>
                        Done <RightOutlined />
                    </Button>

                    </div>

                </div>           

            </Modal>
            <div className="formMainCnt">
                <p className="formTitle">{data?.name}</p>
                <div className={
                    `
                    formInputCnt 
                    ${(data?.error) && "doublePopupempty"} 
                    ${(pass) && "doublePopupfilled"}
                 ` 
                }>

                <div
                    className="mainInput myselect"
                    style={{height:"100%",flex:"1",cursor:"pointer",display:"flex",alignItems:"center",color:"gray"}}
                    onClick={clickFunc}
                    >Click to edit</div>

                    <Question className="formQuestion" onClick={()=> setShowMsg(true)}
                    myRef={msgRef} error={data?.error || false} />
                </div>

                {
                    data?.error &&
                    <Alert 
                    style={{fontSize:"12px",padding:"5px 10px"}} 
                    description="One or more sub-fields are Empty!" 
                    type="error" 
                    showIcon={false} />
                }
                {
                    showMsg &&
                    <div className="formMsg" ref={msgCnt}>
                       {
                         version === "v3" ?
                         (data && data.msg) &&
                             data.msg.map((elem,index)=>(
                                 <p key={`msg${index}`}>{elem}</p>
                             )) 
                         :
                         <p>{data?.msg}</p>
                       }
                    </div>
                }
            </div>
        </>
    )

}

export default OneScreenPopup;