import InputBox from '../components/input';
import Style from '../styles/login.module.css';
import email_prefix from "../svg/email-prefix.svg";
import Button from 'antd/es/button';
import { useEffect, useState } from 'react';
import { Alert } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import NotAuthenticatedLayout from '../components/layouts/NotAuthenticatedLayout';
import { Axios } from '../Axios';

const Forget = ()=>{

    const navigator = useNavigate();
    const [ email, setEmail ] = useState<string | undefined>("");
    const [loading, setLoading] = useState(false);
    const [error,setError] = useState<string | undefined>();
    const [allowView,setAllowView] = useState(false);
 
    const ApiHit = ()=>{
        setLoading(true);
        setError(undefined);

        Axios.post(`/api/v1/forget-password`,{
            email:email
        })
        .then(result=>{

            setLoading(false);
            navigator(`/verify?key=${result.data.data.refData}`);
        })
        .catch(err=>{
            setLoading(false);
            setError(err.response.data.message);
            console.log(err);
        })
    }

    return (
        <>
        <NotAuthenticatedLayout>
        {
            <div id="main">
            <div className={Style.container}>

            <div className={Style.left}>
                <div className={Style.form}>
                    <img src='/images/cco-small.png' alt="logo" />
                    <h1 className={Style.title}>Sign in</h1>
                    <h4 className={Style.subtitle}>Sign in with your email</h4>

                    <div style={{margin:"20px 0 0 0"}}>
                    <InputBox 
                    type='text' 
                    image={<img src={email_prefix} 
                    alt='logo' />} 
                    placeholder='Your email'
                    value={email}
                    onChange={(e)=> setEmail(e.target.value) }
                    />
                    </div>

                    {
                        error &&
                        <Alert 
                        type='error' 
                        message={error} 
                        showIcon 
                        style={{minHeight:"20px", margin:"5px 0 20px 0",fontSize:"12px"}}
                        />
                    }
                 

                    <Button className='btn' type='text' loading={loading} onClick={ApiHit}>Send verification code</Button>

                    <p style={{color:"#656A72",fontSize:"12px",margin:"30px 0 0 0"}}>
                        I think I remember my password. 
                        <Link to="/" style={{textDecoration:"none"}}>
                            <span style={{color:"#2B80FF"}}> Login</span>
                        </Link>
                    </p>

                </div>

            </div>

            <div className={Style.right} style={{
                background: 'url("/images/forget_bk.png")',
                backgroundPosition: 'right center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100%',
            }}>

            </div>

            </div>

        </div>
        }
        </NotAuthenticatedLayout>
        </>
    )
}

export default Forget;