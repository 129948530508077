import { ReactNode } from "react";

type FSType = {
    children : ReactNode
}
const FSLoader = ({children}:FSType)=>{

    return(
        <>
         <div style={{
                    position:"fixed",
                    top:0,
                    left:0,
                    background:"rgba(255,255,255,.8)",
                    zIndex:10,
                    height:"100vh",
                    width:"100vw",
                    display:"flex",
                    alignItems:"center",
                    justifyContent:"center"
                }}>
            <p style={{
                fontSize:"42px"
            }}>{children}</p>

        </div>
        </>
    )

}

export default FSLoader;