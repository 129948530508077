import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Style from "../styles/calc.module.css";

const Clock = ()=>{

    const time = useSelector((state: RootState) => state.calculator.time );

    return(
        <>
        <div className={Style.clock}>{time}</div>
        <div className={Style.clockCircle}>
        {
            time.split(",").map((elem,index)=> (<p key={index}>{elem}</p>))
        }
        </div>
        </>
    )

}

export default Clock;