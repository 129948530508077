import { Print } from "@mui/icons-material";
import { Button } from "antd";
import Style from "../styles/output.module.css";

const PrintBtn = ()=>{

    const printHandler = ()=>{
        window.print();
        return;

    }

    return(
        <Button
                type="text" 
                className={`${Style.rtBtn}`}
                onClick={printHandler}
                >
                    <Print />
                    Print
                </Button>
    )

}

export default PrintBtn;