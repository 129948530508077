import { CloseOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ModalBox from "../modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { removeScenario, setActiveScenario, updateScenarioName } from "../../redux/calculatorSlice";

type propType={
    type:number,
    name:string,
    index:number,
    showClose:boolean
}
const BottomTab = ({type,name, index,showClose}:propType)=>{

    const activeScenario = useSelector((state: RootState) => state.calculator.activeScenario);
    const dispatch = useDispatch();
    const inp = useRef<any>();
    const [nameEdit,setNameEdit] = useState<boolean>(false);
    const [removeScenarioAlert,setRemoveScenarioAlert] = useState(false);
    const [error,setError] = useState("");

    const removeTab = (ind:number)=>{
     
        dispatch(removeScenario(ind));
    }

    return(
        <>
        <div className={`bottomTab ${activeScenario === index ? "btActive" : "btnDeactive"}`}>
        {
            (nameEdit && type !== 0) ?
            <Input 
            type="text"
            ref={inp}
            style={{color:"black"}}
            value={name}
            onChange={(e)=> dispatch(updateScenarioName(e.target.value)) } 
            onBlur={(e)=> {
                if(!e.target.value){
                    dispatch( updateScenarioName("untitled"))
                }
                setNameEdit(false);
            }}
            onKeyDownCapture={(e)=>{
                if(e.code === "Enter" || e.code === "NumpadEnter"){
                    setNameEdit(false);
                }
            }}
            autoFocus
            />
            :
            <p 
            onDoubleClick={()=> type!==0 ? setNameEdit(true) : setError("Changing name is not allowed for default Scenarios !")}
            className="tabName"
            onClick={()=> dispatch(setActiveScenario(index)) }
            >
                {name}
            </p>
        }
        {
            showClose &&
            <CloseOutlined 
            className="tabCloseBtn"
            onClick={()=> setRemoveScenarioAlert(true) }
            />
        }
        {
            removeScenarioAlert &&
            <ModalBox
            open={removeScenarioAlert}
            onClose={()=> setRemoveScenarioAlert(false) }
            header="Scenario Removal !"
            message={`Are you sure you want to delete ${name} permanently ?`} 
            onOk={()=>{
                removeTab(index);
                setRemoveScenarioAlert(false);
            }}
            onCancel={()=>{
                setRemoveScenarioAlert(false);
            }}
            />
        }
        {
        error &&
            <ModalBox
            open={error ? true : false}
            onClose={()=> setError("") }
            header="Alert !"
            message={error} 
            onOk={()=>{
                setError("");
            }}
            />
        }
        </div>
        </>
    )

}

export default BottomTab;