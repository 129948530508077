import { CloseOutlined } from "@ant-design/icons"
import { Input } from "antd"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import { removeTab, setActiveTab, updateTabName } from "../../redux/calculatorSlice"

type props={
    index:number,
    name:string,
    tabLength:number
}
const TopTab = ({index,name,tabLength}:props)=>{

    const activeTab = useSelector((state: RootState) => state.calculator.activeTab);
    const dispatch = useDispatch();

    const [nameEdit,setNameEdit] = useState(false);

    return(
        <>
        <div 
        className={`tab ${activeTab === index ? 'activeTab' : 'deactiveTab'}`}
        >
            {
                nameEdit &&
                <Input 
                type="text"
                style={{color:"black"}}
                value={name}
                onChange={(e)=> dispatch(updateTabName(e.target.value)) } 
                onBlur={(e)=> {
                    if(!e.target.value){
                        dispatch(updateTabName("untitled"));
                    }
                    setNameEdit(false);
                }}
                onKeyDownCapture={(e)=>{
                    if(e.code === "Enter" || e.code === "NumpadEnter"){
                        setNameEdit(false);
                    }
                }}
                autoFocus
                />
            }
            {
                !nameEdit &&
                <p 
                onDoubleClick={()=> setNameEdit(true)}
                className="tabName" 
                onClick={()=> dispatch(setActiveTab(index)) }>
                    {name}
                </p>
            }
                {
                tabLength > 1 &&
                <CloseOutlined 
                className="tabCloseBtn"
                onClick={()=> dispatch(removeTab(index)) }
                />
                }
        </div>
        </>
    )
}

export default TopTab;